var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"upsert-user-access-ip"},[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('div',[_c('b-form-group',[_c('label',{staticClass:"required-control font-weight-bolder"},[_vm._v(" Nhập địa chỉ ip: ")]),_c('b-form-input',{staticClass:"input-style",attrs:{"size":"sm","placeholder":"Địa chỉ ip","autocomplete":"off","state":_vm.validateState('ip')},model:{value:(_vm.$v.dataModel.ip.$model),callback:function ($$v) {_vm.$set(_vm.$v.dataModel.ip, "$model", $$v)},expression:"$v.dataModel.ip.$model"}}),_c('b-form-invalid-feedback',[_vm._v("Địa chỉ ip không hợp lệ")])],1),_c('b-form-group',[_c('label',{staticClass:"required-control font-weight-bolder"},[_vm._v(" Trạng thái ")]),_c('b-form-select',{staticClass:"select-style",attrs:{"size":"sm","options":[
              {
                id: 1,
                name: 'Kích hoạt',
              },
              {
                id: 2,
                name: 'Đã khoá',
              },
            ],"value-field":"id","text-field":"name"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("Chọn trạng thái ")])]},proxy:true}]),model:{value:(_vm.dataModel.status),callback:function ($$v) {_vm.$set(_vm.dataModel, "status", $$v)},expression:"dataModel.status"}})],1)],1)]),_c('b-col',{attrs:{"cols":"9"}})],1),(_vm.checkPermissions(['USER_ACCESS_UPDATE','USER_ACCESS_INSERT']))?_c('b-button',{staticClass:"font-weight-bolder mr-4",staticStyle:{"width":"80px"},attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.upsertData}},[_c('span',[_vm._v(_vm._s(_vm.id ? 'Cập nhật' : 'Tạo mới'))])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }