<template>
  <div class="upsert-user-access-ip">
    <div v-if="isType('jobTitle')">
      <b-row>
        <b-col cols="4">
          <div>
            <b-form-group>
              <label class="required-control font-weight-bolder">
                Chức vụ:
              </label>
              <b-form-select
                class="select-style"
                size="sm"
                v-model="dataModel.jobTitleId"
                :options="optionsJobTitles"
                required
                value-field="id"
                text-field="name"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null"
                    >Chọn chức vụ</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </div>

    <div v-if="isType('employee')">
      <b-row>
        <b-col cols="4">
          <div>
            <b-form-group>
              <label class="required-control font-weight-bolder">
                Nhân viên:
              </label>
              <Autosuggest
                :model="dataModel.employeeName"
                :suggestions="filteredEmployees"
                :suggestionName="'fullName'"
                :placeholder="'nhân viên CSKH'"
                :limit="100"
                @select="onSelected($event, 'Employee')"
                @change="onInputed($event, 'Employee')"
              >
                <template #custom="{ suggestion }">
                  {{ suggestion.item.fullName }}
                </template>
              </Autosuggest>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </div>

    <div v-if="isType('store')">
      <b-row>
        <b-col cols="4">
          <div class="d-block">
            <b-form-group>
              <label class="required-control font-weight-bolder">
                Cửa hàng:
              </label>
              <Autosuggest
                :model="dataModel.storeName"
                :suggestions="filteredStores"
                :suggestionName="'name'"
                :placeholder="'cửa hàng'"
                :limit="100"
                @select="onSelected($event, 'Store')"
                @change="onInputed($event, 'Store')"
              />
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col cols="4">
        <b-form-group>
          <label class="required-control font-weight-bolder">
            Trạng thái
          </label>
          <b-form-select
            class="select-style"
            size="sm"
            v-model="dataModel.status"
            :options="[
              {
                id: 1,
                name: 'Kích hoạt',
              },
              {
                id: 2,
                name: 'Đã khoá',
              },
            ]"
            value-field="id"
            text-field="name"
          >
            <template v-slot:first>
              <b-form-select-option :value="null"
                >Chọn trạng thái
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-button
      class="font-weight-bolder mr-4"
      size="sm"
      variant="primary"
      style="width: 80px"
      @click="upsertData"
      v-if="checkPermissions(['USER_ACCESS_UPDATE','USER_ACCESS_INSERT'])"
    >
      <span>{{ id ? 'Cập nhật' : 'Tạo mới' }}</span></b-button
    >
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { convertPrice, cloneDeep, removeAccents } from '@/utils/common';
import { makeToastSuccess, makeToastFaile } from '@/utils/common';
import { ipAddress } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { checkPermissions } from '@/utils/saveDataToLocal';

export default {
  props: {
    id: {
      type: Number,
    },
    type: {
      type: String,
    },
  },
  mixins: [validationMixin],
  data() {
    return {
      dataModel: {
        // store
        storeId: null,
        storeName: null,
        // jobTitle
        jobTitleId: null,
        // employeeId
        employeeId: null,
        // common
        status: null,
      },
      onLoading: false,
      optionsJobTitles: [],
      filteredEmployees: [],
      filteredStores: [],
      optionOrigin: {},
    };
  },
  validations: {
    dataModel: {
      ip: {
        ipAddress,
      },
    },
  },
  components: {
    Autosuggest,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ pageTitle: this.pageTitle }]);
  },
  created() {
    if (this.id) {
      this.fetchDataById(this.id);
    }
    switch (this.type) {
      case 'jobTitle': {
        this.fetchJobTitle(this.type);
        break;
      }
      case 'employee': {
        this.fetchEmployees();
        break;
      }
      case 'store': {
        this.fetchStore(this.type);
        break;
      }
    }
  },
  methods: {
    checkPermissions,
    convertPrice,
    makeToastSuccess,
    isType(type) {
      if (Array.isArray(type)) {
        return type.includes[this.type];
      }
      return this.type === type;
    },
    fetchJobTitle(type) {
      ApiService.query(`/user-accesses/enum/`, {
        params: {
          type: type,
        },
      }).then((response) => {
        const { data } = response.data;
        this.optionsJobTitles = data;
        this.filteredTargets = data;
      });
    },
    fetchStore() {
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        this.filteredStores = stores;
        this.optionOrigin.stores = stores;
        this.filteredTargets = stores;
      });
    },
    fetchEmployees: function () {
      ApiService.get('employees/getAll').then((response) => {
        if (response.data.status) {
          const employees = response.data.data.map((item) => {
            return Object.assign(item, {
              name: item.fullName,
            });
          });
          this.filteredEmployees = employees;
          this.optionOrigin.employees = employees;
          this.filteredTargets = employees;
        }
      });
    },
    fetchDataById(id) {
      ApiService.get(`/user-accesses/${id}/${this.type}`).then((response) => {
        const { data } = response.data;
        this.dataModel = data;
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.dataModel[name];
      return $dirty ? !$error : null;
    },
    upsertData() {
      const method = this.id ? 'put' : 'post';
      ApiService[method]('/user-accesses', {
        ...this.dataModel,
        id: this.id,
        type: this.type,
      })
        .then(() => {
          makeToastSuccess('Thành công');
          this.$router.go(-1);
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
          this.$nprogress.done();
        });
    },
    onSelected(option, type) {
      switch (type) {
        case 'Employee':
          this.dataModel.employeeId = option.item.id;
          this.dataModel.employeeName = option.item.fullName;
          break;
        case 'Store':
          this.dataModel.storeId = option.item.id;
          this.dataModel.storeName = option.item.name;
          break;
        default:
          break;
      }
    },
    onInputed(textInput = '', type) {
      switch (type) {
        case 'Employee':
          this.dataModel.employeeId = null;
          this.filteredEmployees = this.fitlerOptionsBy(
            this.optionOrigin.employees,
            textInput,
            'fullName',
            10,
          );
          if (this.filteredEmployees.length === 1) {
            this.dataModel.employeeId = this.filteredEmployees.id;
          }
          break;
        case 'Store':
          this.dataModel.storeId = null;
          this.filteredStores = this.fitlerOptionsBy(
            this.optionOrigin.stores,
            textInput,
            'name',
            10,
          );
          if (this.filteredStores.length === 1) {
            this.dataModel.storeId = this.filteredStores.id;
          }
          break;
        default:
          break;
      }
    },
    fitlerOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
  },
};
</script>