<template>
  <div class="upsert-user-access-ip">
    <b-row>
      <b-col cols="3">
        <div>
          <b-form-group>
            <label class="required-control font-weight-bolder">
              Nhập địa chỉ ip:
            </label>
            <b-form-input
              class="input-style"
              size="sm"
              v-model="$v.dataModel.ip.$model"
              placeholder="Địa chỉ ip"
              autocomplete="off"
              :state="validateState('ip')"
            ></b-form-input>
            <b-form-invalid-feedback
              >Địa chỉ ip không hợp lệ</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group>
            <label class="required-control font-weight-bolder">
              Trạng thái
            </label>
            <b-form-select
              class="select-style"
              size="sm"
              v-model="dataModel.status"
              :options="[
                {
                  id: 1,
                  name: 'Kích hoạt',
                },
                {
                  id: 2,
                  name: 'Đã khoá',
                },
              ]"
              value-field="id"
              text-field="name"
            >
              <template v-slot:first>
                <b-form-select-option :value="null"
                  >Chọn trạng thái
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </div>
      </b-col>
      <b-col cols="9"> </b-col>
    </b-row>
    <b-button
      class="font-weight-bolder mr-4"
      size="sm"
      variant="primary"
      style="width: 80px"
      @click="upsertData"
      v-if="checkPermissions(['USER_ACCESS_UPDATE','USER_ACCESS_INSERT'])"
    >
      <span>{{ id ? 'Cập nhật' : 'Tạo mới' }}</span></b-button
    >
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { convertPrice } from '@/utils/common';
import { makeToastSuccess, makeToastFaile } from '@/utils/common';
import { ipAddress } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { checkPermissions } from '@/utils/saveDataToLocal';

export default {
  props: {
    id: {
      type: String,
    },
  },
  mixins: [validationMixin],
  data() {
    return {
      dataModel: {
        id: null,
        ip: '',
        status: null,
      },
      onLoading: false,
    };
  },
  validations: {
    dataModel: {
      ip: {
        ipAddress,
      },
    },
  },
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ pageTitle: this.pageTitle }]);
  },
  created() {
    this.id = this.$route.params.id || null;
    this.type = this.$route.query.type || 'ip';
    if (this.id) {
      this.fetchDataById(this.id);
    }
  },
  methods: {
    checkPermissions,
    convertPrice,
    makeToastSuccess,
    isType(type) {
      if (Array.isArray(type)) {
        return type.includes[this.type];
      }
      return this.type === type;
    },
    fetchDataById(id) {
      ApiService.get(`/ip-configurations/${id}`).then((response) => {
        const { data } = response.data;
        this.dataModel = data;
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.dataModel[name];
      return $dirty ? !$error : null;
    },
    upsertData() {
      const method = this.id ? 'put' : 'post';
      const endPoint =
        this.type === 'ip' ? '/ip-configurations' : '/user-accesses';
      ApiService[method](endPoint, {
        ...this.dataModel,
        id: this.id,
        type: this.type,
      })
        .then(() => {
          makeToastSuccess('Thành công');
          this.$router.go(-1);
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
          this.$nprogress.done();
        });
    },
  },
};
</script>